<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical" class="sign-form" :rules="rules">
        <div class="header-block">
          <sdHeading as="h3" class="text-center">
            {{  $t('auth.forgot_password') }}
            <p class="small-text">{{$t('auth.we_will_help_you')}}</p>
          </sdHeading>
        </div>

        <div class="text-block">
          <p class="forgot-text">
            {{  $t('auth.notice') }}
          </p>
        </div>

        <div class="input-block">
          <a-form-item :label="$t('auth.username')" name="email">
            <a-input type="email" v-model:value="formState.email" />
          </a-form-item>
        </div>

        <div class="button-block">
          <a-form-item>
            <sdButton class="btn-reset element-center" htmlType="submit" type="primary" size="large" :loading="isLoading"> {{ $t('auth.recover_password') }} </sdButton>
          </a-form-item>
        </div>

        <p class="return-text">{{  $t('auth.return_to') }} <router-link to="/auth/login">{{  $t('auth.sign_in') }}</router-link></p>
      </a-form>
      <!-- <a-modal v-model:visible="errorModal" title="Error" @ok="closeModal">
        {{$t('auth.forgot_password_error')}}
      </a-modal> -->
      <!-- <a-modal v-model:visible="successModal" :title="$t('generic.titles.new_password_message')" @ok="closeModal">
        {{$t('auth.forgot_password_success')}}
      </a-modal> -->
    </div>
  </AuthWrapper>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { AuthWrapper } from './style';
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import { Modal } from 'ant-design-vue';

const { t } = useI18n();

const authStore = useAuthStore();

// const errorModal = ref(false)
// const showErrorModal = () => errorModal.value = true

// const successModal = ref(false)
// const showSuccessModal = () => successModal.value = true

// const closeModal = () => {
//   errorModal.value = false
//   successModal.value = false
// }

const success = () => {
  Modal.success({
    title: t('generic.titles.new_password_message'),
    content: t('auth.forgot_password_success'),
    width: 550,
    // onOk() {
    //   authStore.success = false;
    // },
  });
};


const error = () => {
  Modal.error({
    title: t('generic.titles.error'),
    content: t('auth.forgot_password_error'),
    width: 550,
    // onOk() {
    //   authStore.success = false;
    // },
  });
};


const handleSubmit = (formState) => {
  authStore.forgotPassword(formState.email)
  .then(() => {
    //  showSuccessModal();
    success();
  })
  .catch(() => 
    // showErrorModal()
    error()
  )
};

const formState = reactive({
  email: '',
});

const rules = {
  email: [{ required: true, message: t('auth.rules.email'), trigger: 'change' }],
};

const isLoading = computed(() => authStore.loading)

</script>
<style scoped lang="scss">

.auth-contents {
  height: 100%;
}
.sign-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

// FORM HEADER
.lavNKW .auth-contents form h1 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 991px) {
  .duPWIV {
    padding: 85px !important;
  }
  .auth-contents {
    height: 72vh;
  }
}
</style>